import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import Seo from "../components/seo";
import Layout from "../layouts";
import { graphql, Link } from "gatsby";
import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image';
import SecondButton from "../components/Button/SecondButton"
import {
	BreadCrumb,
	Section,
	BaseButton,
	SectionPageTitle,
	SectionDescription,
	Container,
} from '../components/Section'
import CtaSection from '../sections/CtaSection';
import PhoneIcon from "../components/Icons/PhoneIcon"

const HeroContainer = styled.div`
	border-top: 1px solid #D0D0D0;
	border-bottom: 1px solid #D0D0D0;
	padding: 40px 0;
	margin-bottom: 40px;
`

const GalleryList = styled.div`
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;		
	margin: 0 -15px;
	padding:0 15px;
	@media (min-width: 1200px) {
		margin:0 -20px;
	}
`
const GalleryItem = styled.div`
	position:relative;
	width: 100%;
	padding: 0 15px;
	@media (min-width: 768px) {
		flex:0 0 50%;
		max-width:50%;
	}
	@media (min-width: 1200px) {
		padding:0  20px;
	}
`
const GalleryCard = styled.div`
	background-color:#fff;
	position: relative;		
	height:calc(100% - 30px);
	margin-bottom:30px;
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
	@media (min-width: 1200px) {
		height:calc(100% - 40px);
		margin-bottom:40px;
	}
`
const GalleryFigure = styled.div`
	.gatsby-image-wrapper {
		width: 100%;
		height: 100%;
	}
`
const GalleryTitle = styled.div`
	font-size: 16px;
	line-height:28px;
	margin:5px 0;
	text-align:center;
	@media (min-width: 768px) {
		text-align:left;
	}

	> a{
		color: #000;
		font-family: "Eina03-Bold", sans-serif;
	}
`
const GalleryAction = styled.div`
	margin:5px 0px 10px 0px;
	.btn{
		.text{white-space:nowrap}
	}
`
const GalleryCaption = styled.div`	
	background: #fff;
	padding: 10px 20px;
	display:flex;
	align-items:center;
	justify-content:space-between;
	flex-direction:column;
	@media (min-width: 992px) {
		flex-direction:row;
	}
`
const SkuWrap = styled.div`
	position: absolute;
	z-index: 2;
	top: 10px;
	left: -22px;
	width: 180px;

	.gatsby-image-wrapper {
		width: 100%;
		height: 100%;
	}
	.sku {
		position: absolute;
		top: 8px;
		left: 30px;
		width: 150px;
		font-size: 14px;
		line-height: 19px;
		color: white;
		text-transform: uppercase;
	}
`

const LoadMoreButton = styled(BaseButton)`
  background: #D51333;
  color:#fff;
  border-radius: 6px;
  box-shadow: 0 6px 0 #982221;
  font-family: "Oswald", sans-serif;
  margin: auto;
  display: block;
  width: fit-content;

  &:after {		
    background: #A20000;
  }
  &:hover {
    cursor: pointer;
  }
`

const Gallery = ({ data, location }) => {
	const [showLimit, setShowLimit] = useState(10);
	const products = data.allContentfulProduct.edges;
	const [currentCategory, setCurrentCategory] = useState('all');
	const [currentSubCategory, setCurrentSubCategory] = useState("all");
	const [currentProducts, setCurrentProducts] = useState(products);
	
	useEffect(() => {
		let currentProductsTemp = [];
		products.forEach(function(item) {
			if (item.node.category?.name === currentCategory) {
				currentProductsTemp.push(item);
			}
		})
		setCurrentSubCategory('all');
		setCurrentProducts(currentProductsTemp)
			
	}, [currentCategory, setCurrentCategory]);

	useEffect(() => {
		if (currentCategory === 'all') {
			setCurrentProducts(products);
		} else {
			if (currentSubCategory === 'all') {
				let currentProductsTemp = [];
				products.forEach(function(item) {
					if (item.node.category?.name === currentCategory) {
						currentProductsTemp.push(item);
					}
				})
				setCurrentProducts(currentProductsTemp)
			} else {
				let currentProductsTemp = [];
				products.forEach(function(item) {
					if (item.node.category?.name === currentCategory && item.node.subCategory?.name ===currentSubCategory) {
						currentProductsTemp.push(item);
					}
				})
				setCurrentProducts(currentProductsTemp)
			}
		}
	}, [currentSubCategory, setCurrentSubCategory]);
	
	return(
		<Layout footerCta location={location}>
			<Seo title="Check out our Product Gallary - Buildings And More" description="Check out the pictures of steel buildings, storage buildings, pole barns, gazebos, greenhouses, and screen rooms that we offer." />
			<BreadCrumb position="relative">
				<Container>
					<Link to="/">Home</Link><span>&nbsp;/&nbsp;</span>
					<span>Product Gallery</span>
				</Container>
			</BreadCrumb>
			<Section pb="0" pt="0" mb="100px" mpt="0" mpb="0">
				<HeroContainer className="container">
					<SectionPageTitle>Product Gallery</SectionPageTitle>
					<SectionDescription maxWidth="1120px">
						We stock a range of styles and sizes, including steel buildings, storage buildings, pole barns, gazebos, greenhouses, and screen rooms. Take a look at our products to find the right one that fits your needs.
					</SectionDescription>
				</HeroContainer>
				<Container maxWidth="1120px">
					<GalleryList>
						{
							currentProducts.map((item, i) => i < showLimit && (
								<GalleryItem>
									<GalleryCard>
										<SkuWrap>
											<StaticImage src="../images/sku.png" placeholder="blurred" formats={["AUTO", "WEBP", "AVIF"]} alt="sku" />
											<div className="sku">sku: {item.node.sku}</div>
										</SkuWrap>
										<GalleryFigure>
											<Link to={item.node.url}>
												<GatsbyImage image={getImage(item.node.image.gatsbyImageData)} alt={item.node.name} />
											</Link>
										</GalleryFigure>
										<GalleryCaption>
											<GalleryTitle><Link to={item.node.url}>{item.node.name}</Link></GalleryTitle>
											<GalleryAction><a href="tel:3867556449"><SecondButton text="(386) 755-6449" icon={<PhoneIcon fill="#ffffff" />} /></a></GalleryAction>
										</GalleryCaption>
									</GalleryCard>
								</GalleryItem>
							))
						}
						{currentProducts.length > showLimit && (
							<LoadMoreButton onClick={()=>setShowLimit(showLimit + 10)}>Load More Buildings</LoadMoreButton>
						)}
					</GalleryList>
				</Container>
			</Section>
			<CtaSection />
		</Layout>
	)
}

export default Gallery

export const query = graphql`
  query GalleryPageQuery {
  	allContentfulProduct {
      edges {
        node {
          category {
            name
          }
          subCategory {
          	name
          }
          name
          sku
          width
          length
          height
          image {
            gatsbyImageData(placeholder: BLURRED, quality: 50)
          }
          startingPrice
          downPayment
          url
        }
      }
    }
    allContentfulCategory {
	    edges {
	      node {
	        name
	      }
	    }
	  }
	  allContentfulSubCategory {
	    edges {
	      node {
	        category {
	          name
	        }
	        name
	      }
	    }
	  }
  }
`